import React, { useState, useEffect } from 'react';
import { getStrapiCollections } from '../data/getStrapiCollections';

const NoticeLabel = () => {
  const [notice, setNotice] = useState([])
  const collectionData = 'notice-labels?populate=*'

  useEffect(() => {
    const fetchData = async () => {
    const data = await getStrapiCollections(collectionData)
    setNotice(data)

    }
    fetchData()
  }, [])

  return (
    <>
      {notice?.map(({noticeTitle, noticeSubtitle, noticeBackgroundColor, id}) => (
        <div key={id} className='w-full py-6 flex justify-center flex-col items-center text-center' style={{background: `${noticeBackgroundColor}`}}>
          <h3 className='text-white font-semibold text-[16px] md:text-[22px]'>{noticeTitle}</h3>
          <h3 className='text-white font-semibold text-[16px] md:text-[22px]'>{noticeSubtitle}</h3>
        </div>
      ))}
    </>
  )
}

export default NoticeLabel;

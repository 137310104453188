import React, { useState, useEffect } from 'react'
import { getStrapiCarousel } from '../data/getStrapiCarousel'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ServiceCarousel = ({ updateOption }) => {
  const [servicesCarouselData, setServicesCarouselData] = useState([])
  const collectionData = 'services-galleries?populate[carousel_services][populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCarousel(collectionData)
      setServicesCarouselData(data)
    }
    fetchData()
  }, [])

  const handleOption = (option) => {
    updateOption(option);
  };

  return (
    <section id='services' className='h-1/2 mx-auto px-4 sm:px-6 md:px-8 py-6 bg-neutral-100 dark:bg-slate-800'>
      {servicesCarouselData?.map(({ id, serviceCarouselTitle, carouselServices }) => (
        <div key={id}>
          <h1 className='pt-5 pb-8 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl'>
            <span className='text-slate-700/70 dark:text-slate-300'>{serviceCarouselTitle}</span>
          </h1>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="mySwiper"
          >
            {carouselServices?.map(({ id, image, title }) => (
              <SwiperSlide key={id}>
                <div className='rounded-lg shadow-black/40 shadow-xl my-10 mx-4 md:w-[12rem]'>
                  <div className='rounded-t-lg justify-items-center' style={{ height: '200px'}}>
                    <img src={image} className='w-full h-full rounded-t-lg' alt={title} />
                  </div>
                  <div className='grid justify-items-center dark:bg-slate-900/50 py-2 rounded-b-lg'>
                    <h3 className='text-slate-700/70 dark:text-white mt-5 text-base font-medium tracking-tight'>{title}</h3>
                    <button onClick={() => handleOption(title)} className='cursor-pointer inline-flex items-center justify-center p-0.5 border-2 border-green-300 text-xs mt-2 bg-green-400/20 dark:bg-green-800/20 dark:border-green-600/50 rounded-md shadow-lg'>
                      View gallery
                    </button>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}
    </section>
  )
}

export default ServiceCarousel

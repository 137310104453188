export const getStrapiCollections = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        heroTitle: attributes?.hero_title,
        heroImage: process.env.REACT_APP_STRAPI_URL + attributes?.hero_image?.data?.attributes?.url,
        navbarLogo: process.env.REACT_APP_STRAPI_URL + attributes?.navbar_logo?.data?.attributes?.url,
        aboutTitle: attributes?.about_title,
        aboutSubtitle: attributes?.about_subtitle,
        aboutContent: attributes?.about_content,
        aboutImage: process.env.REACT_APP_STRAPI_URL + attributes?.about_image?.data?.attributes?.url,
        noticeTitle: attributes?.title,
        noticeSubtitle: attributes?.subtitle,
        noticeBackgroundColor: attributes?.backgroundColor,
      }
    }
  )

  return values
}

export const getStrapiServicesList = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_STRAPI_KEY}`
    }
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        serviceTitle: attributes?.title,
        serviceSubtitle: attributes?.subtitle,
        serviceContent: attributes?.content,
        serviceCards: attributes?.list_of_services?.data?.map(({attributes, id}) => ({
          id: id,
          cardTitle: attributes?.service_title,
          cardSubtitle: attributes?.service_subtitle,
          cardImage: process.env.REACT_APP_STRAPI_URL + attributes?.service_image?.data?.attributes?.url
        }))
      }
    }
  )

  return values
}

import React, { useState, useEffect } from 'react'
import { getStrapiServiceCards } from '../data/getStrapiServiceCards'

function ServicesCards() {
  const [ data, setData ] = useState([])
  const collectionData = 'service-cards?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServiceCards(collectionData)
      setData(data)
    }
    fetchData()
  },[])

  return (
    <section className='bg-neutral-100/90 dark:bg-slate-800/90 py-[3rem]'>
      <div className='w-[80%] mx-auto  flex justify-center items-center gap-6 flex-wrap '>
        {data?.map(({ id, cardServiceTitle, cardServiceIcon, squareBackground }) => (
          <div key={id} className='w-[12rem] h-[12rem] rounded flex justify-center items-center flex-col' style={{ background: squareBackground}}>
            <img src={cardServiceIcon} alt="icon" className='w-[40%] mb-4' />
            <span className='text-white uppercase font-[14px] font-semibold'>{cardServiceTitle}</span>
          </div>
        ))}
      </div>
    </section>
  )
}

export default ServicesCards

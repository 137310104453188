import React, { useState, useEffect } from 'react'
import { getStrapiGallery } from '../data/getStrapiGallery'

const Gallery = ({ option }) => {
  const [galleryData, setGalleryData] = useState([]);
  const collectionData = 'service-gallery-images?populate[gallery_images][populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiGallery(collectionData);
      setGalleryData(data);
    };
    fetchData();
  }, []);

  const Image = ({ image }) => {
    if (!image) {
      return <p>No image available</p>;
    }

    return (
      <div className="flex flex-wrap w-1/2">
        <div className="w-full h-full p-1 md:p-2">
          <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg"
            src={image} />
        </div>
      </div>
    );
  };

  return (
    <section id='gallery' className='h-1/2 mx-auto px-4 sm:px-6 md:px-8 pt-6 pb-6 bg-neutral-100 dark:bg-slate-800'>
    {galleryData.length > 0 && option && (
        galleryData
          .filter(item => item.galleryServiceTitle.trim().toLowerCase() === option.trim().toLowerCase())
          .map(({ id, galleryImages }) => (
            <div key={id}>
              <h1 className='pt-5 pb-8 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl'>
                <span className='text-slate-700/70 dark:text-slate-300'>Gallery</span>
                <p className='text-md text-slate-700/50 dark:text-slate-300/80'>{option}</p>
              </h1>
              <section className="overflow-hidden text-gray-700">
                <div className="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
                  <div className="flex flex-wrap justify-center">
                    {galleryImages?.map((img, index) => (
                      <Image key={index} image={img.image} />
                    ))}
                  </div>
                </div>
              </section>
            </div>
          ))
      )}
    </section>
  )
}

export default Gallery

import React, { useState, useEffect } from 'react'
import { getStrapiCollections } from '../data/getStrapiCollections'

const Home = () => {
  const [homeData, setHomeData] = useState([])
  const collectionData = 'hero-sections?populate[hero_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCollections(collectionData)
      setHomeData(data)
    }
    fetchData()
  }, [])

  return (
    <section className='flex w-full flex-col md:flex-row'>
      { homeData?.map(({ id, heroImage, heroTitle }) => (
        <div id='home' key={id} className='w-full md:w-[50%] h-[60vh] bg-cover flex md:flex-row' style={{ backgroundImage: `url(${heroImage})` }}>
          <div className='h-full w-full bg-gradient-to-tr from-cyan-500/40 dark:from-neutral-200/20 to-neutral-500/40 dark:to-slate-900 bg-cover'>
            <div className='grid justify-items-center space-x-4 w-full h-full'>
              <div className='flex items-center justify-center w-full h-full'>
                <div className='text-center'>
                  <h1 className='font-bold sm:md:text-3xl text-xl text-white dark:text-neutral-200'>{`${heroTitle}`}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  )
}

export default Home

import React, { useState, useEffect } from 'react'
import { getStrapiCollections } from '../data/getStrapiCollections'

const About = () => {
  const [aboutData, setAboutData] = useState([])
  const collectionData = 'about-sections?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiCollections(collectionData)
      setAboutData(data)
    }
    fetchData()
  }, [])

  return (
    <>
      {aboutData?.map(({ id, aboutTitle, aboutSubtitle, aboutContent, aboutImage }) => (
        <div key={id} className='bg-neutral-100/90 dark:bg-slate-800/90 flex flex-col items-center w-full' id='about'>
          <div className='mx-auto my-4 container'>
            <div className='grid justify-items-center w-full text-center sm:md:my-10 my-2'>
              <p className='sm:md:text-3xl text-xl font-light text-blue-900 dark:text-sky-500 py-2'>{aboutTitle}</p>
              <p className='sm:md:text-xl text-md text-blue-900 dark:text-sky-400 py-2'>{aboutSubtitle}</p>
              <p key={id} className='sm:md:text-sm text-xs sm:md:px-10 px-5 text-blue-900/80 dark:text-sky-400 py-2'>{aboutContent}</p>
            </div>
          </div>
          <img
            src={aboutImage}
            className='w-3/4 md:w-1/2 rounded-lg mb-10'
            alt='About Av. Painting'
          />
        </div>
      ))}
    </>
  )
}

export default About
